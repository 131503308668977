import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  panel: {
    minHeight: 200,
  },
  alert: {
    marginTop: 16,
  },
});

export default useStyles;
