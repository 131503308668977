import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  alert: {
    margin: '16px 0',
    whiteSpace: 'pre-line',
  },
  form: {
    marginTop: 10,
  },
  link: {
    color: theme.palette.primary,
    fontWeight: 600,
    fontSize: 14,
  },
  forgotPasswordLink: {
    color: theme.palette.primary,
    fontWeight: 500,
    fontSize: 14,
  },
  icon: {
    height: 48,
    width: 48,
  },
}));

export default useStyles;
