import { useState } from 'react';

const useForgottenPassword = ({ handleForgottenPassword, handleForgottenPasswordSubmit }) => {
  const defaultFormValues = {
    resetCode: '', password: '', confirmPassword: '', email: '',
  };
  const [formData, setFormData] = useState(defaultFormValues);
  const [formStep, setFormStep] = useState(0);
  const [error, setError] = useState(null);

  const nextFormStep = () => setFormStep(() => formStep + 1);

  const onSubmitForgotPasswordRequest = async (event) => {
    try {
      await handleForgottenPassword(event?.email);
      setFormData({ ...formData, ...event });
      nextFormStep();
    } catch (err) {
      setError('Something went wrong. Please try again or contact an administrator.');
    }
  };

  const onSubmitForgotPassword = async (event) => {
    try {
      const { email, resetCode, password } = event;
      await handleForgottenPasswordSubmit(email, resetCode, password);
      nextFormStep();
    } catch (err) {
      setError('Failed to update password. Please try again or contact an administrator.');
    }
  };

  return {
    formData,
    formStep,
    onSubmitForgotPasswordRequest,
    onSubmitForgotPassword,
    error,
  };
};

export default useForgottenPassword;
