import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  panel: {
    minHeight: 100,
  },
  alert: {
    marginTop: 32,
  },
});

export default useStyles;
