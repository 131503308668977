import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    margin: '120px auto auto',
  },
  paper: {
    padding: '24px',
  },
});

export default useStyles;
