import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { FormTitle } from '~common/components';
import useStyles from './styles';

const ConfirmationPanel = () => {
  const { classes } = useStyles();

  return (
    <>
      <FormTitle
        title="Forgotten password"
        description="Successfully updated password."
        align="center"
      />
      <Grid container columnSpacing={1} className={classes.panel}>
        <Grid item xs={12}>
          <Alert severity="success" className={classes.alert}>
            Please return to{' '}
            <Link
              href="/"
              underline="none"
            >
              Sign In
            </Link>
          </Alert>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmationPanel;
