import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useStyles from './styles';

const FormTextField = ({
  control, name, rules, inputProps, label, readOnly,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {label && (
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            onChange={onChange}
            value={value}
            margin="normal"
            fullWidth
            error={Boolean(error)}
            helperText={error?.message}
            InputProps={{ readOnly }}
            {...inputProps}
          />
        )}
      />
    </>
  );
};
FormTextField.defaultProps = {
  rules: {},
  inputProps: {},
  label: '',
  readOnly: false,
};

FormTextField.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};

export default FormTextField;
