/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { Alert, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { validationSchemas } from '~common/utils';
import {
  Button,
  FormTitle,
  FormActionWrapper,
  FormTextField,
} from '~common/components';
import useStyles from './styles';

const ForgottenPasswordRequest = ({ onSubmit, error, formData }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm(
    {
      defaultValues: formData,
      resolver: yupResolver(object({
        email: string()
          .required(validationSchemas.errorMessages.requiredField)
          .email(validationSchemas.errorMessages.email),
      }).required()),
    },
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.title}>
        <FormTitle
          title="Forgotten password"
          description="Please enter the email address used when registering."
          image="/logo.svg"
        />
      </div>
      <Typography variant="body1" className={classes.description}>
        Should you have an account, you will receive an email containing a reset code
        that you will need to enter on the next page to complete the password reset process.
      </Typography>
      {error && (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      )}
      <FormTextField
        name="email"
        control={control}
        inputProps={{
          label: 'Email Address',
        }}
      />
      <FormActionWrapper>
        <Button onClick={() => navigate('/')} variant="text" className={classes.button} disableRipple>
          Back
        </Button>
        <Button type="submit">Submit</Button>
      </FormActionWrapper>
    </form>
  );
};

ForgottenPasswordRequest.defaultProps = {
  error: null,
};

ForgottenPasswordRequest.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
};

export default ForgottenPasswordRequest;
