import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 600,
  },
}));

export default useStyles;
