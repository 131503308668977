import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Button, FormContainer, FormTitle } from '~common/components';
import useStyles from './styles';

const RegistrationConfirmation = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <FormContainer maxWidth="sm">
      <FormTitle
        title="Sign Up Confirmation"
        image="/logo.svg"
      />
      <Grid container columnSpacing={1} className={classes.panel}>
        <Grid item xs={12}>
          <Alert severity="info" className={classes.alert}>
            You will shortly receive a link by e-mail which you will need to click to confirm your account. You will need to confirm your email address before being able to sign-in. If you do not receive the e-mail, please check your &apos;Spam&apos; or &apos;Junk E-mail&apos;.
          </Alert>
        </Grid>
        <Grid item xs={2}>
          <Button variant="text" onClick={() => navigate('/')}>Sign in</Button>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default RegistrationConfirmation;
