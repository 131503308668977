import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  alert: {
    margin: '16px 0',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 10,
  },
  button: {
    color: theme.palette.primary,
    fontWeight: 500,
    fontSize: 16,
  },
}));

export default useStyles;
