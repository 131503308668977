import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  alert: {
    margin: '16px 0',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 10,
  },
  description: {
    color: '#969696',
    textAlign: 'center',
    marginBottom: 16,
  },
  button: {
    justifyContent: 'flex-start',
    color: theme.palette.primary,
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkbox: {
    color: 'rgba(150, 150, 150, 0.6)',
    '&.Mui-checked': {
      color: '#162C5F',
    },
  },
  label: {
    color: theme.palette.primary,
    fontWeight: 500,
  },
  link: {
    color: theme.palette.primary,
    textDecoration: 'underline',
    textDecorationColor: theme.palette.primary,
    cursor: 'pointer',
  },
  icon: {
    height: 48,
    width: 48,
  },
}));

export default useStyles;
