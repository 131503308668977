import { useState } from 'react';
import { API } from '@aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import { config } from '~common/utils';

const formatFormData = (formData) => ({
  ...formData,
  email: formData?.email?.toLowerCase(),
});

const useSignUpForm = ({ formFields }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(formFields);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    setFormData(Object.assign(formData, data));

    const apiName = import.meta.env.VITE_AWS_API_NAME;
    const path = config.path.signup;
    const myInit = {
      headers: {},
      body: formatFormData(formData),
    };

    try {
      await API.post(apiName, path, myInit);
      navigate('/registration-confirmation');
    } catch (e) {
      setError('There has been an error submitting the form please contact an administrator.');
    }
  };

  return {
    formData,
    onSubmit,
    error,
  };
};

export default useSignUpForm;
