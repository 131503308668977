import { string, object } from 'yup';
import fields, { errorMessages } from './rules';

export const passwordSchema = object({
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
}).required();

export const changePasswordSchema = object({
  email: string()
    .when('$exist', {
      is: (exist) => exist,
      then: fields.emailRequired,
      otherwise: string().email(errorMessages.email),
    }),
  currentPassword: fields.passwordRequired,
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
});

export const updatePasswordSchema = object({
  name: fields.firstNameRequired,
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
});

export const forgottenPasswordSchema = object({
  resetCode: fields.resetCodeRequired,
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
}).required();

export const signInSchema = object({
  email: fields.emailRequired.lowercase(errorMessages.lowercaseEmail),
  password: string().required(errorMessages.requiredField),
}).required();

export const registerSchema = object({
  name: fields.firstNameRequired,
  email: fields.emailRequired.lowercase(errorMessages.lowercaseEmail),
  password: fields.passwordRequired,
  confirmPassword: fields.confirmPasswordRequired,
}).required();

const validationSchemas = {
  changePasswordSchema,
  errorMessages,
  fields,
  forgottenPasswordSchema,
  passwordSchema,
  registerSchema,
  signInSchema,
  updatePasswordSchema,
};

export default validationSchemas;
