import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
  formTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: props?.align && props?.align,
  },
  title: {
    marginTop: 8,
    marginBottom: 4,
    color: theme.palette.primary,
    fontWeight: 600,
  },
  description: {
    color: '#969696',
  },
  image: {
    width: 150,
  },
}));

export default useStyles;
