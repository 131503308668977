import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  backLink: {
    color: theme.palette.primary,
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    color: theme.palette.primary,
    fontWeight: 500,
  },
  checkbox: {
    color: 'rgba(150, 150, 150, 0.6)',
    '&.Mui-checked': {
      color: theme.palette.primary,
    },
  },
  emailInput: {
    '& .MuiInputBase-input': {
      textTransform: 'lowercase',
    },
  },
  icon: {
    height: 48,
    width: 48,
  },
}));

export default useStyles;
