import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox, FormControlLabel, FormGroup, Grid,
} from '@mui/material';
import { validationSchemas } from '~common/utils';
import {
  FormTitle,
  FormTextField,
  Button,
  FormContainer,
  FormActionWrapper,
} from '~common/components';
import useSignUpForm from './hooks/useSignUpForm';
import formFields from '../../utils/registrationFields.json';
import useStyles from './styles';

const Register = () => {
  const {
    formData,
    onSubmit,
  } = useSignUpForm({
    formFields,
  });
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control } = useForm(
    { defaultValues: formData, resolver: yupResolver(validationSchemas.registerSchema) },
  );

  return (
    <FormContainer maxWidth="sm">
      <FormTitle
        title="Sign up"
        image="/logo.svg"
      />
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formPanel} noValidate>
        <Grid container columnSpacing={1}>
          <Grid item xs={12}>
            <FormTextField
              name="name"
              control={control}
              inputProps={{
                label: 'Name',
                autoFocus: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name="email"
              control={control}
              inputProps={{
                label: 'Email Address',
                required: true,
                className: classes.emailInput,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="password"
              control={control}
              inputProps={{
                label: 'Password',
                type: showPassword ? 'text' : 'password',
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="confirmPassword"
              control={control}
              inputProps={{
                label: 'Confirm',
                type: showPassword ? 'text' : 'password',
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                    className={classes.checkbox}
                  />
              )}
                className={classes.label}
                label="Show Password"
              />
            </FormGroup>
            <FormActionWrapper>
              <Button onClick={() => navigate('/')} variant="text" className={classes.backLink} disableRipple>
                Log in instead
              </Button>
              <Button type="submit">Submit</Button>
            </FormActionWrapper>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};

export default Register;
