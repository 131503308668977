const config = {
  apiName: 'miles',
  path: {
    // signup: '/signup',
    signup: '/user/signup',
    register: '/register',
    changePassword: '/change-password',
    forgottenPassword: '/forgotten-password',
  },
};

export default config;
