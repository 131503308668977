import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link, Alert } from '@mui/material';
import { validationSchemas } from '~common/utils';
import { config } from '~common/utils';
import {
  Button,
  FormContainer,
  FormTitle,
  FormTextField,
  FormActionWrapper,
} from '~common/components';
import useStyles from './styles';

const SignIn = ({ handleSignIn }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const skillsPageUrl = import.meta.env.VITE_SKILLS_DOMAIN;
  const { path } = config;
  const [error, setError] = useState('');

  const { handleSubmit, control } = useForm(
    {
      defaultValues: {
        email: '',
        password: '',
      },
      resolver: yupResolver(validationSchemas.signInSchema),
    },
  );

  const onSubmit = async (event) => {
    try {
      const res = await handleSignIn(event?.email, event?.password);

      // Users who have been given a temporary password will need to set a
      // permanent one via the confirm password page.
      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate(path.changePassword);
      } else {
        window.location.replace(skillsPageUrl);
      }
    } catch (err) {
      switch (err.name) {
        case 'UserNotConfirmedException':
          return setError('Please confirm your email address before attempting to sign in');
        case 'NotAuthorizedException':
          return setError(
            'Unable to sign in.'
            + '\r\n\r\nIf you\'re unsure about your password try resetting it using the Forgotten'
            + ' Password link above.',
          );
        default:
          setError(`Error signing in: ${err}`);
      }
    }
  };

  return (
    <FormContainer maxWidth="sm">
      <FormTitle
        title="Sign in"
        image="/logo.svg"
        align="center"
      />
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
        <FormTextField
          name="email"
          control={control}
          inputProps={{
            label: 'Email',
          }}
        />
        <FormTextField
          name="password"
          control={control}
          inputProps={{
            label: 'Password',
            type: 'password',
          }}
        />
        <Link
          href={path.forgottenPassword}
          underline="none"
          className={classes.forgotPasswordLink}
        >
          Forgotten your password?
        </Link>
        {error && (
        <Alert
          severity="error"
          className={classes.alert}
        >
          <span>{error}</span>
        </Alert>
        )}
        <FormActionWrapper>
          <Link href={path.register} underline="none" className={classes.link}>Register for an account</Link>
          <Button type="submit">
            Sign in
          </Button>
        </FormActionWrapper>
      </form>
    </FormContainer>
  );
};

SignIn.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
};

export default SignIn;
