import { useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.signOut()
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  return <div className="sign-out" />;
}

export default SignOut;