import ReactDOM from 'react-dom/client';
import { Amplify } from '@aws-amplify/core';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '~common/utils';
import './main.css';
import App from './app';

// Configure AWS Amplify.
Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
    cookieStorage: {
      domain: import.meta.env.VITE_COOKIE_DOMAIN,
      secure: import.meta.env.VITE_COOKIE_SECURE,
    },
  },
  API: {
    endpoints: [
      {
        name: import.meta.env.VITE_AWS_API_NAME,
        endpoint: import.meta.env.VITE_AWS_APIGATEWAY_URL,
        region: import.meta.env.VITE_AWS_REGION,
      },
    ],
  },
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
