import dayjs from 'dayjs';
import {
  number, string, object, ref, boolean,
} from 'yup';

export const errorMessages = {
  agreement: 'Please confirm you have agreed to the following',
  email: 'Must be a valid email',
  forgottenPasswordResetCode: 'Password reset code is a 6 digit number',
  lowercaseEmail: 'Email must be lowercase',
  minRequiredChars: 'This field must be at least 3 characters',
  passwordDoesNotMatch: 'Passwords do not match',
  passwordLowerCase: 'Password must contain at least 1 lower case letter',
  passwordMinRequiredChars: 'Password must be at least 8 characters',
  passwordNumber: 'Password must contain at least 1 number',
  passwordSpecialChar: 'Password must contain at least 1 special character',
  passwordUpperCase: 'Password must contain at least 1 upper case letter',
  requiredField: 'This field is required',
};

const rules = {
  minRequiredChars: string()
    .required(errorMessages.requiredField).min(3, errorMessages.minRequiredChars),
};

const fieldValues = {
  addressLine1: string().min(3, errorMessages.minRequiredChars),
  addressLine1Required: rules.minRequiredChars,
  addressLine2: string().nullable(),
  city: string().min(3, errorMessages.minRequiredChars),
  cityRequired: rules.minRequiredChars,
  confirmPasswordRequired: string()
    .required(errorMessages.requiredField)
    .oneOf([ref('password')], errorMessages.passwordDoesNotMatch),
  countryName: object(),
  countryNameRequired: object().required(errorMessages.requiredField),
  dateOfBirth: string(),
  dateOfBirthRequired: string()
    .nullable()
    .test('is-date', 'Not a valid date', (value) => dayjs(value).isValid())
    .test('is-future', 'Not valid, date is in the future', (value) => dayjs(value).isBefore(dayjs()))
    .test('min-age', 'Sorry, too young for platform', (value) => dayjs(value).isBefore(dayjs().subtract(6, 'year')))
    .required(errorMessages.requiredField),
  email: string().email(errorMessages.email),
  emailRequired: string().required(errorMessages.requiredField).email(errorMessages.email),
  firstName: string().min(3, errorMessages.minRequiredChars),
  firstNameRequired: rules.minRequiredChars,
  idRequired: string().required(),
  lastName: string().min(3, errorMessages.minRequiredChars),
  lastNameRequired: rules.minRequiredChars,
  passwordRequired: string()
    .required(errorMessages.requiredField)
    .min(8, errorMessages.passwordMinRequiredChars)
    .matches(/[a-z]+/, errorMessages.passwordLowerCase)
    .matches(/[A-Z]+/, errorMessages.passwordUpperCase)
    .matches(/.*[\^$*.[\]{}()?"!@#%&\/\\,><':;|_~`=+\- ]{1,}.*/, errorMessages.passwordSpecialChar) // eslint-disable-line
    .matches(/\d+/, errorMessages.passwordNumber),
  phoneNumber: number().typeError('Must be a number').positive().integer(),
  phoneNumberPrefix: object(),
  phoneNumberPrefixRequired: object().required(errorMessages.requiredField),
  phoneNumberRequired: number().typeError('Must be a number')
    .required(errorMessages.requiredField).positive()
    .integer(),
  postCode: string(),
  postCodeRequired: string().required(errorMessages.requiredField),
  resetCodeRequired: string()
    .required(errorMessages.requiredField)
    .min(6, errorMessages.forgottenPasswordResetCode),
  uuidRequired: string().uuid().required(),
  verifyUserRequired: boolean().required(),
};

const fields = {
  ...fieldValues,
};

export default fields;
