import { useEffect, useState } from 'react';

const useAuth = ({ Hub, Auth }) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const newUser = await Auth.currentAuthenticatedUser();
        setCognitoUser(newUser);
        setIsAuthenticated(true);
      } catch {
        setCognitoUser(null);
        setIsAuthenticated(false);
      }
      setLoading(false);
    };
    Hub.listen('auth', updateUser); // listen for login/signup events
    updateUser();
    return () => Hub.remove('auth', updateUser); // cleanup
  }, []);

  const handleSignIn = async (email, password) => {
    const res = await Auth.signIn(email, password);
    setCognitoUser(res);
    return res;
  };

  const handleCompleteNewPassword = async (newUser, password, name) => {
    const res = await Auth.completeNewPassword(newUser, password, { name });
    setCognitoUser(res);
    return res;
  };

  const handleForgottenPassword = async (email) => {
    const res = await Auth.forgotPassword(email);
    setCognitoUser(res);
    return res;
  };

  const handleForgottenPasswordSubmit = async (email, code, newPassword) => {
    const res = await Auth.forgotPasswordSubmit(email, code, newPassword);
    setCognitoUser(res);
    return res;
  };

  const handleChangePassword = async ({ currentPassword, newPassword }) => {
    try {
      return await Auth.changePassword(cognitoUser, currentPassword, newPassword);
    } catch (error) {
      if (error.name === 'LimitExceededException' || error.name === 'TooManyRequestsException') {
        return 'LIMIT';
      }
      if (error.name === 'NotAuthorizedException') {
        return 'NOAUTH';
      }
      return 'ERROR';
    }
  };

  const handleChangeEmail = async ({ email }) => {
    try {
      return await Auth.updateUserAttributes(cognitoUser, {
        email,
      });
    } catch (error) {
      if (error.name === 'LimitExceededException' || error.name === 'TooManyRequestsException') {
        return 'LIMIT';
      }
      if (error.name === 'NotAuthorizedException') {
        return 'NOAUTH';
      }
      return 'ERROR';
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      throw new Error(error);
    }
  };

  const groups = cognitoUser?.signInUserSession?.accessToken?.payload['cognito:groups'];

  const user = {
    id: cognitoUser?.attributes?.sub ?? '',
    email: cognitoUser?.attributes?.email ?? '',
    isAuthenticated,
    groups,
  };

  return {
    user,
    loading,
    handleSignOut,
    handleSignIn,
    handleCompleteNewPassword,
    handleForgottenPassword,
    handleForgottenPasswordSubmit,
    handleChangePassword,
    handleChangeEmail,
    cognitoUser,
  };
};

export default useAuth;
