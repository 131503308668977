import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert, FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { validationSchemas } from '~common/utils';
import {
  Button,
  FormTitle,
  FormActionWrapper,
  FormTextField,
} from '~common/components';
import useStyles from './styles';

const ForgottenPasswordSubmit = ({ onSubmit, error, formData }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control } = useForm(
    {
      defaultValues: formData,
      resolver: yupResolver(validationSchemas.forgottenPasswordSchema),
    },
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.title}>
        <FormTitle
          title="Create new password"
          description="Please enter the password reset code emailed to you and create a new password."
          image="/logo.svg"
        />
      </div>
      {error && (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
      )}
      <FormTextField
        name="resetCode"
        control={control}
        inputProps={{
          label: 'Reset Code',
        }}
      />
      <FormTextField
        name="password"
        control={control}
        inputProps={{
          label: 'New Password',
          type: showPassword ? 'text' : 'password',
        }}
      />
      <FormTextField
        name="confirmPassword"
        control={control}
        inputProps={{
          label: 'Confirm Password',
          type: showPassword ? 'text' : 'password',
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              className={classes.checkbox}
            />
          )}
          className={classes.label}
          label="Show Password"
        />
      </FormGroup>
      <FormActionWrapper>
        <Button onClick={() => navigate('/')} variant="text" className={classes.button} disableRipple>
          Back
        </Button>
        <Button type="submit">Submit</Button>
      </FormActionWrapper>
    </form>
  );
};

ForgottenPasswordSubmit.defaultProps = {
  error: null,
};

ForgottenPasswordSubmit.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape({}).isRequired,
};

export default ForgottenPasswordSubmit;
