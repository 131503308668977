import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox, FormControlLabel, FormGroup, Link, Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { validationSchemas } from '~common/utils';
import {
  Button,
  FormContainer,
  FormTitle,
  FormActionWrapper,
  FormTextField,
} from '~common/components';
import useStyles from './styles';

const ChangePassword = ({ user, handleCompleteNewPassword }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (event) => {
    try {
      handleCompleteNewPassword(user, event?.password, event?.name);
      navigate('/');
    } catch (err) {
      setError(`Error updating password: ${err}`);
    }
  };

  const { handleSubmit, control } = useForm(
    {
      defaultValues: { confirmPassword: '', password: '', name: '' }, resolver: yupResolver(validationSchemas.updatePasswordSchema),
    },
  );

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.title}>
          <FormTitle
            title="Update Password"
            description="Type in your new password below"
            image="/logo.svg"
            align="center"
          />
        </div>
        {error && (
        <Alert severity="error" className={classes.alert}>
          {error}
        </Alert>
        )}
        <FormTextField
          name="name"
          control={control}
          inputProps={{
            label: 'Name',
            type: 'text',
          }}
        />
        <FormTextField
          name="password"
          control={control}
          inputProps={{
            label: 'New Password',
            type: showPassword ? 'text' : 'password',
          }}
        />
        <FormTextField
          name="confirmPassword"
          control={control}
          inputProps={{
            label: 'Confirm Password',
            type: showPassword ? 'text' : 'password',
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={showPassword} onChange={() => setShowPassword(!showPassword)} />
          }
            label="Show Password"
          />
        </FormGroup>
        <FormActionWrapper>
          <Link href="/" underline="none" className={classes.button}>Back</Link>
          <Button type="submit">Submit</Button>
        </FormActionWrapper>
      </form>
    </FormContainer>
  );
};

ChangePassword.defaultProps = {
  user: {},
};

ChangePassword.propTypes = {
  user: PropTypes.shape({}),
  handleCompleteNewPassword: PropTypes.func.isRequired,
};

export default ChangePassword;
