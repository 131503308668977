import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import { useAuth } from '~common/utils';
import SignIn from './pages/signin';
import ChangePassword from './pages/changePassword';
import ForgottenPassword from './pages/forgottenPassword';
import Register from './pages/register';
import SignOut from './pages/signout';
import RegistrationConfirmation from './pages/registrationConfirmation';

function App() {
  const {
    cognitoUser,
    handleSignIn,
    handleCompleteNewPassword,
    handleForgottenPassword,
    handleForgottenPasswordSubmit,
  } = useAuth({ Hub, Auth });

  return (
    <Router>
      <Routes>
        <Route index element={<SignIn handleSignIn={handleSignIn} />} />
        <Route
          path="/register"
          element={<Register />}
        />
        <Route
          path="/registration-confirmation"
          element={(
            <RegistrationConfirmation />
          )}
        />
        <Route
          path="/change-password"
          element={(
            <ChangePassword
              user={cognitoUser}
              handleCompleteNewPassword={handleCompleteNewPassword}
            />
          )}
        />
        <Route
          path="/forgotten-password"
          element={(
            <ForgottenPassword
              handleForgottenPassword={handleForgottenPassword}
              handleForgottenPasswordSubmit={handleForgottenPasswordSubmit}
            />
          )}
        />
        <Route
          path="/sign-out"
          element={(
            <SignOut />
          )}
        />
      </Routes>
    </Router>
  );
}

export default App;
