import { createTheme } from '@mui/material/styles';
import colors from './colors';

const theme = createTheme(
  {
    palette: {
      background: {
        default: colors.secondary,
      },
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      custom: {
        ...colors,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '::-ms-clear': {
              display: 'none',
            },
            '::-ms-reveal': {
              display: 'none',
            },
            '::-webkit-search-decoration': {
              display: 'none',
            },
            '::-webkit-search-cancel-button': {
              display: 'none',
            },
            '::-webkit-search-results-button': {
              display: 'none',
            },
            '::-webkit-search-results-decoration': {
              display: 'none',
            },
          },
        },
      },
    },
  },
);

export default theme;
